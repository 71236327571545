@import "dlg";
@import '~@angular/material/prebuilt-themes/pink-bluegrey.css';

body {
    background-color:#0B0F16;
    font-family: Poppins;
    overflow-x: hidden;
    min-height: 100vh;
    position: relative;
}


.heads{
    display: none;
}

html{
    overflow-x: hidden;

}

button,input{
    outline: none;
}

app-root{
    display: flex;
    flex-direction: column;
    min-height: inherit;
    justify-content: space-between;
}

.bg_1::before{
    content: '';
    background-image: url(/assets/images/bg.png);
    width: 726px;
    height: 732px;
    display: block;
    position: absolute;
    z-index: -1;
    opacity: 0.4;
    top: -250px;
    left: 150px;
}


.bg_1::after{
    content: '';
    background-image: url(/assets/images/bg.png);
    width: 414px;
    height: 410px;
    display: block;
    position: absolute;
    z-index: -1;
    opacity: 0.4;
    top: 250px;
    right: 50px;
    background-size: contain;
}

.bg_2::after{
    content: '';
    background-image: url(/assets/images/bg.png);
    width: 104px;
    height: 104px;
    display: block;
    position: absolute;
    z-index: -1;
    opacity: 0.4;
    top: 200px;
    right: 300px;
    background-size: contain;
}

.bg_2::before{
    content: '';
    width: 750px;
    height: 750px;
    right: -60px;
    top: 85px;
    position: absolute;
    border-radius: 864px;
    opacity: 0.2099999940395355;
    background: var(--main, linear-gradient(90deg, #F8BF28 0%, #70FFA0 100%));
    filter: blur(201.5px);
}

.bg_3::before{
  content: "";
  width: 750px;
  height: 750px;
  left: 120px;
  top: -75px;
  position: absolute;
  border-radius: 864px;
  opacity: 0.2099999940395355;
  background: var(--main, linear-gradient(90deg, #53F6ED 0%, #53A8F6 100%));
  filter: blur(201.5px);
}

.bg_1,.bg_2,.bg_3{
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    overflow: hidden;
    height: 100%;
}

.head-right{
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.container{
    width: 1180px;
    margin: 0 auto;
}

header .head-right a{
    height: 56px;
    padding: 0 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF !important;
    text-decoration: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
}

.summary_btn{
    display: flex;
    justify-content: space-between;
}

.claming_btn{
    display: flex;
    justify-content: space-between;
}

.summary_btn_on{
    transform: rotate(270deg);
    margin-top: -10px;
    cursor: pointer;
}

body.setting_summary_openid .setting_user_item_summary{
    height: 30px;
    overflow: hidden;
    transition: 0.5s;
}

.claming_btn_on{
    transform: rotate(270deg);
    margin-top: -10px;
    cursor: pointer;
}

body.setting_claming_openid .setting_user_item_claming{
    height: 30px;
    overflow: hidden;
    transition: 0.5s;
}

.edit{
   height: 56px;
   width: 56px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    text-decoration: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
}

header .head-right .open_au span{
    width: 86px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.summary_set{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.summary_set .setting_user_item{
    margin-top: 0 !important;
}

.calendae_blok{
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    padding: 24px;
}

.calendae_blok .datepicker{
    background: none !important;
    backdrop-filter: blur(0px) !important;
    width: 100% !important;
}

.calendae_blok .datepicker--cell.-current-{
    height: 46px;
}

.calendae_blok .datepicker--cell{
    height: 46px;
}

.dist_list{
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    padding: 24px;
    margin-top: 20px;
}

.dist_list_l span{
    font-weight: 400;
    font-size: 12px;
    line-height: 122%;
    color: #FFFFFF;
    opacity: 0.4;
}

.dist_list_r{
    font-weight: 400;
    font-size: 16px;
    line-height: 162%;
    text-align: right;
    color: #FFFFFF;
}

.dist_list_l p{
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 162%;
    color: #FFFFFF;
    opacity: 0.4;
}

.dist_list ul{
    list-style: none;
    padding: 0;
    display: grid;
    gap: 12px;
}

.dist_list ul li{
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    justify-content: space-between;
    padding-bottom: 5px;
}

.add_info{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 35px;
}

button{
    cursor: pointer;
}

.add_info:hover {
    filter: brightness(0.6);
    transition: 0.5s;
}

header .head-right .open_au::after{
    content: '';
    background: url(/assets/images/open_au.png);
    width: 18px;
    height: 18px;
    opacity: 0.4;
    margin-left: 15px;
}

header .head-right .open_arrow::after{
  content: '';
  background: url(/assets/images/prev.png);
  transform: rotate(270deg);
  width: 8px;
  height: 12px;
  opacity: 0.4;
  margin-left: 15px;
}

header .head-right .open_arrow_up::after{
  content: '';
  background: url(/assets/images/prev.png);
  transform: rotate(90deg);
  width: 8px;
  height: 12px;
  opacity: 0.4;
  margin-left: 15px;
}

.menu_btn_mobil{
    display: none;
}

body .summary_set .setting_user_item{
    margin-top: 20px;
}

header .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

h2{
    font-size: 64px;
    line-height: 125%;
    color: #fff;
    margin-top: 80px;
    margin-bottom: 80px;
    font-weight: 400;
}

.tabs_s{
    display: grid;
    grid-template-columns: 380px 1fr;
    gap: 20px;
}

.tabs_block_link{
    backdrop-filter: blur(40px);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.04);
    height: fit-content;
    overflow: hidden;
}

.tabs_block_link_number{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 40px;
    color: rgb(255 255 255 / 40%);
    margin-left: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.tabs_block_link_item{
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 16px;
    line-height: 122%;
    color: rgb(255 255 255 / 40%);
}

.tabs_block_link .end .tabs_block_link_number{
    background-color: rgba(111, 207, 178, 0.24);
    background-image: url(/assets/images/end.png);
    background-repeat: no-repeat;
    background-position: center;
    font-size: 0;
}

.tabs_block_link .end .tabs_block_link_t{
    color: #6FCFB2;
    opacity: 0.4;
}

.tabs_block_link .is-open{
    background: rgba(255, 255, 255, 0.08);
    color: #fff;
}

.tabs_block_link .is-open .tabs_block_link_number{
    color:#fff;
}

.tabs_block_link .tabs_block_link_item{
    cursor: pointer;
    transition: 0.5s;
}

.tabs_block_link .tabs_block_link_item:hover{
    transition: 0.5s;
    color: #fff;
}

.tabs_block_link .tabs_block_link_item:hover .tabs_block_link_number{
    color: #fff;
    transition: 0.5s;
}

.tabs_block_container{
    height: fit-content;
}

.tabs_select_lock{
    display: grid;
    gap: 20px;
}

.vesting_set{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}

.tabs_select_lock .vesting_set .t{
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: 0.4;
}

#date_p{
    width: 100% !important;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
}

#date_p2{
    width: 100% !important;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.vesting-left, .refund-right{
    display: grid;
    gap: 24px;
}

.vesting-left_block input{
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    background: none;
    color: #fff;
    height: 32px;
    box-sizing: border-box;
    margin-top: 1px;
}

.vesting-left_block_date{
    position: relative;
}

.vesting-left_block_date::after{
    position: absolute;
    content: '';
    background: url(/assets/images/calendar.png);
    width: 20px;
    height: 22px;
    background-size: contain;
    right: 5px;
}

.date-time-picker {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    .mat-form-field-flex {
      align-items: center;
      .mat-form-field-infix {
        padding: 0;
        border-top: none;
        .mat-input-element {
          border: 0;
          width: 100%;
          background: none;
          color: #fff;
          height: 32px;
          box-sizing: border-box;
          margin-top: 1px;
          vertical-align: baseline;
        }
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            color: #FFFFFF;
            opacity: 0.4;
          }
        }
      }
      .mat-form-field-suffix {
        .mat-datepicker-toggle {
          color: #FFFFFF;
        }
      }
    }
    .mat-form-field-underline {
      bottom: 0;
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.timepicker-ui-modal{
    background-color: rgb(0 0 0 / 69%) !important;
}

.timepicker-ui-select-time, .timepicker-ui-select-time.mobile{
    text-transform: uppercase;
    position: absolute;
    top: 25px;
    left: 1.5rem;
    font-weight: 400;
    font-size: 12px;
    line-height: 122%;
    color: #FFFFFF;
    opacity: 0.4;
}

.timepicker-ui-wrapper, .timepicker-ui-wrapper.mobile{
    background-color: #15181F !important;
}

.timepicker-ui-hour-text, .timepicker-ui-minute-text, .timepicker-ui-hour-text.mobile, .timepicker-ui-minute-text.mobile{
    bottom: -24px !important;
}

.timepicker-ui-am:hover, .timepicker-ui-am.active, .timepicker-ui-pm:hover, .timepicker-ui-pm.active, .timepicker-ui-am.mobile:hover, .timepicker-ui-am.mobile.active, .timepicker-ui-pm.mobile:hover, .timepicker-ui-pm.mobile.active{
    background: rgba(111, 207, 178, 0.2) !important;
    color: #6FCFB2 !important;
}

.timepicker-ui-hour.mobile[contenteditable='true']:focus, .timepicker-ui-hour.mobile[contenteditable='true']:active, .timepicker-ui-minutes.mobile[contenteditable='true']:focus, .timepicker-ui-minutes.mobile[contenteditable='true']:active{
    border: 0.125rem solid rgba(111, 207, 178, 0.2) !important;
    outline-color: rgba(111, 207, 178, 0.2) !important;
}

.timepicker-ui-hour:hover, .timepicker-ui-hour.active, .timepicker-ui-minutes:hover, .timepicker-ui-minutes.active, .timepicker-ui-hour.mobile:hover, .timepicker-ui-hour.mobile.active, .timepicker-ui-minutes.mobile:hover, .timepicker-ui-minutes.mobile.active{
    background: #272A31 !important;
    border-radius: 4px !important;
    color: #6FCFB2 !important;
}

.timepicker-ui-hour, .timepicker-ui-minutes, .timepicker-ui-hour.mobile, .timepicker-ui-minutes.mobile{
    background-color: #1E2128 !important;
    color: #fff !important;
}

.timepicker-ui-dots, .timepicker-ui-dots.mobile {
    color: #fff !important;
}

.timepicker-ui-wrapper-btn div{
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-radius: 8px !important;
    height: 56px !important;
    box-sizing: border-box !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 134px !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #FFFFFF !important;
}

.timepicker-ui-footer {
  justify-content: center !important;
  margin: 0 !important;
}

.timepicker-ui-wrapper.mobile {
    height: 16.025rem !important;
}

body .dd-pointer-up{
    border-bottom: solid 5px #fff !important;
}

#date_p .dd-select{
    width: 100% !important;
    background: none !important;
    border: 0 !important;
}

#date_p2 .dd-select{
    width: 100% !important;
    background: none !important;
    border: 0 !important;
}

#date_p .dd-selected{
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    padding: 0;
}

#date_p2 .dd-selected{
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    padding: 0;
}

.tabs_select{
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    padding: 24px;
    padding-top: 40px;
}

.tabs_block_container .t{
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.blockchain{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.blockchain .blockchain_item{
    position: relative;
    display: flex;
    height: 76px;
    align-items: center;
}

.blockchain .blockchain_item label{
    position: absolute;
    width: 100%;
    height: 76px;
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    cursor: pointer;
}

.blockchain .blockchain_item input{
    margin-left: 24px;
    box-shadow: none;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.blockchain .blockchain_item input+label::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    opacity: 0.4;
    border-radius: 50%;
    position: absolute;
    left: 24px;
    margin-top: 29px;
}


.blockchain .blockchain_item input:checked+label::before {
    background-image: url(/assets/images/checked.png);
    background-size: 18px;
    border: 0;
    width: 18px;
    height: 18px;
    opacity: 1;
}

.blockchain .blockchain_item input:checked+label{
    background: rgba(111, 207, 178, 0.2);
}

.blockchain .blockchain_item label:hover::before{
    border: 2px solid #fff;
    opacity: 0.8;
}

.blockchain .blockchain_item label:hover{
    background: rgba(255, 255, 255, 0.12);
    transition: 0.5s;
}

.blockchain .blockchain_item .blockchain_item_c{
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    line-height: 122%;
    color: #fff;
    margin-left: 60px;
    height: 76px;
}

footer{
    background: rgba(255, 255, 255, 0.02);
    padding: 24px 0;
    margin-top: 80px;
}

footer .project{
    font-weight: 300;
    font-size: 12px;
    line-height: 180%;
    color: #fff;
    margin-top: 19px;
    text-align: center;
}

footer .container{
    display: flex;
    justify-content: space-between;
}

footer .policy{
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 19px;
}

footer .policy a{
    font-weight: 300;
    font-size: 12px;
    line-height: 180%;
    color: #fff;
    text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

footer .social a{
    filter: brightness(0.6);
    transition: 0.5s;
}

footer .social a:hover{
    filter: brightness(1);
    transition: 0.5s;
}


.times_b .times_b_item input{
    margin-left: 24px;
    box-shadow: none;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.right_form_set .t{
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #FFFFFF;
    margin-bottom: 24px;
}

.times_b .times_b_item input+label::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    opacity: 0.4;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
}


.times_b .times_b_item input:checked+label::before {
    background-image: url(/assets/images/checked.png);
    background-size: 18px;
    border: 0;
    width: 18px;
    height: 18px;
    opacity: 1;
}

.times input{
    margin: 0 !important;
    opacity: 1 !important;
}

.times_b_item{
    height: 58px;
    padding-left: 34px;
    overflow: hidden;
    position: relative;
}

.times_b_item label{
    height: inherit;
    display: flex;
    align-items: center;
    position: relative;
}

.times_b .times_b_item input{
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    background: none;
    color: #fff;
    height: 32px;
    box-sizing: border-box;
    margin-top: 1px;
    z-index: 1;
}

.input_l{
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    background: none;
    color: #fff;
    height: 32px;
    box-sizing: border-box;
    margin-top: 1px;
    z-index: 1;
}

.times_b_item_input{
    width: 100%;
}

.input_l input{
    margin: 0 !important;
    opacity: 1 !important;
}

.cliff_block{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
}

.cliff_item{
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
}


.cliff_item input{
    margin-left: 24px;
    box-shadow: none;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.cliff_item input+label::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    opacity: 0.4;
    border-radius: 50%;
    position: absolute;
    left: 24px;
    margin-top: 4px;
}

.cliff_item .input_t{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    margin-left: 36px;
    margin-bottom: 28px;
}

.cliff_item label{
    padding: 24px;
    display: block;
    height: 100%;
    box-sizing: border-box;
    border-radius: 8px;
}

.cliff_item_input p{
    font-weight: 400;
    font-size: 12px;
    line-height: 122%;
    color: #FFFFFF;
    opacity: 0.4;
    margin: 0;
}

.cliff_item_input .t{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: 0.4;
    margin: 0;
}

.cliff_item_bottom{
    display: grid;
    grid-template-columns: 56px 1fr;
    gap: 10px;
    align-items: end;
    margin-top: 24px;
}

.cliff_item_bottom_item input{
    max-width: 122px;
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    background: none;
    color: #fff;
    height: 32px;
    box-sizing: border-box;
    margin-top: 1px;
}

#cliff_p {
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    background: none;
    color: #fff;
}

input{
    outline: none;
}

select{
    outline: none;
}

#cliff_p option{
    color: #000;
    border: 0;
}

.table_unl_item_block{
    background: rgba(255, 255, 255, 0.04);
    border-radius: 8px;
    padding: 12px;
}

.table_unl_item_block .opps{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #F2C94C;
    margin-top: 24px;
}

.table_unl_item{
    display: grid;
    grid-template-columns: 40px 60px 205px 120px 60px 35px;
    gap: 24px;
    align-items: center;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 8px;
    padding: 12px;
}

.receiving_wallet, .request_refund{
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    display: flex;
    padding: 22px 24px;
    margin-top: 20px;
    /*display: grid;
    grid-template-columns: 1fr 180px;
    gap: 24px;*/
}

.cliff_item_top input{
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    background: none;
    color: #fff;
    height: 32px;
    box-sizing: border-box;
    margin-top: 1px;
}

.unl_add{
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 56px;
    background: none;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items:center;
    gap: 16px;
}

.project-bottom_ver3 .project-bottom-top {
    display: grid;
    padding: 0 24px;
    grid-template-columns: 250px 250px 250px 250px;
}

.receiving_wallet_block .t{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: 0.4;
}

.project-bottom_ver3 .project-bottom-b {
    display: grid;
    padding: 0 24px;
    height: 80px;
    grid-template-columns: 250px 250px 250px 1fr 56px;
}

.project-bottom_ver3 .project-bottom-b a{
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: none;
    opacity: 1;
    width: 56px;
}

.receiving_wallet_block input{
    font-size: 16px;
    line-height: 32px;
    background: none;
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    color: #fff;
}

.head-right a:hover {
    filter: brightness(0.6);
    transition: 0.5s;
}

.all_clime_item_activ a:hover {
    filter: brightness(0.6);
    transition: 0.5s;
}

button:hover {
    filter: brightness(0.6);
    transition: 0.5s;
}

.receiving_wallet button, .request_refund button {
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 56px;
    background: none;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    transition: 0.5s;
    cursor: pointer;
}

.tabs_select_result{
    gap: 12px;
    display: grid;
}

.tabs_select_result div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cviz_btn2{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
}

.cviz_btn1{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 20px;
}

.cviz_btn1 button:hover {
    filter: brightness(0.6);
    transition: 0.5s;
}

.cviz_btn1 button{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.cviz_btn2 .approve button,.cviz_btn2 .lock button{
    background: linear-gradient(90deg, #53F6ED 0%, #53A8F6 100%);
    border-radius: 8px;
    width: 100%;
    height: 56px;
    border: 0;
    cursor: pointer;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.cviz_btn2 .back button {
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 56px;
    background: none;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.cviz_btn2 button:hover {
    filter: brightness(0.6);
    transition: 0.5s;
}

.tabs_select_result div p{
    font-weight: 400;
    font-size: 16px;
    line-height: 162%;
    color: #FFFFFF;
    margin: 0;
}

.tabs_select_result div span{
    font-weight: 400;
    font-size: 16px;
    line-height: 162%;
    color: #FFFFFF;
    opacity: 0.4;
}

.table_unl_n span{
    background: rgba(255, 255, 255, 0.04);
    border-radius: 40px;
    display: block;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 162%;
    text-align: center;
    color: #FFFFFF;
}

.table_unl{
    display: grid;
    gap: 12px;
}

.table_unl_input .t{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: 0.4;
    margin: 0;
}

.table_unl_input input{
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    background: none;
    color: #fff;
    height: 32px;
    box-sizing: border-box;
    margin-top: 1px;
    z-index: 1;
}

.table_unl_input_date{
    position: relative;
}

.table_unl_input_date::after {
    position: absolute;
    content: '';
    background: url(/assets/images/calendar.png);
    width: 20px;
    height: 22px;
    background-size: contain;
    right: 5px;
    bottom: 6px;
}

.cliff_item_form input{
    opacity: 1;
    z-index: 1;
    margin: 0;
    position: initial;
}

.cliff_item input:checked+label::before {
    background-image: url(/assets/images/checked.png);
    background-size: 18px;
    border: 0;
    width: 18px;
    height: 18px;
    opacity: 1;
    margin-top: 4px;
}

.cliff_item input:checked+label{
    background: rgba(111, 207, 178, 0.2);
    display: block;
    padding: 24px;
}

.cliff_item label:hover::before{
    border: 2px solid #fff;
    opacity: 0.8;
}

.cliff_item label:hover{
    background: rgba(255, 255, 255, 0.12);
    transition: 0.5s;
}

.times{
    height: 32px;
    display: block;
}

.times_b_item .blockchain_item_c{
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
}

.times_b{
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    padding: 24px;
}

.times_b_t{
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.times_b .input_t{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: 0.4;
}

.times_block{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-top: 40px;
}

.times_b .times_b_item label:hover::before{
    border: 2px solid #fff;
    opacity: 0.8;
}

.block_balance{
    display: grid;
    grid-template-columns: 1fr 1fr 100px;
    gap: 25px;
}

.block_balance button{
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
}

.block_balance_item .t{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: 0.4;
    margin: 0;
}

.block_balance_item input{
    font-size: 16px;
    line-height: 32px;
    background: none;
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    color: #fff;
}

.cviz_btn{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
}

.enter_address_opaciti{
    opacity: 0.4;
}

.cviz_btn .back button{
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 56px;
    background: none;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    transition: 0.5s;
    cursor: pointer;
}

.cviz_btn .back button:hover{
    filter: brightness(0.6);
    transition: 0.5s;
}

.cviz_btn .next button{
    background: linear-gradient(90deg, #53F6ED 0%, #53A8F6 100%);
    border-radius: 8px;
    width: 100%;
    height: 56px;
    border: 0;
    cursor: pointer;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    transition: 0.5s;
    outline: none;
}

input{
    outline: none;
}

select{
    outline: none;
}

button{
    outline: none;
}

body .project_claimer_bott_ver2 {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 780px;
    gap: 24px;
}

.summary_set .tabs_select{
    background: none;
    backdrop-filter: blur(0px);
    padding: 0 ;
}

body .project_claimer_token_ver2{
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    gap: 10px;
}

.wait_is-open .popap{
    display: block !important;
}

.request_popap{
    display: none;
}

.wait_popap{
    display: none;
}

.wait_is-open .wait_popap{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background: #0D0E0F;
    backdrop-filter: blur(40px);
    padding: 32px;
    border-radius: 8px;
    color: #fff;
    width: 500px;
    padding-bottom: 60px;
}

.successful_popap{
    display: none;
}

.successful_popap_is-open .popap{
    display: block !important;
}

.successful_popap_is-open .successful_popap{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background: #0D0E0F;
    backdrop-filter: blur(40px);
    padding: 32px;
    border-radius: 8px;
    color: #fff;
    width: 500px;
    padding-bottom: 60px;
}

.change_popap_btn_1fr{
    display: grid;
    grid-template-columns: 1fr !important;
    margin-top: 60px !important;
}

.summary_set .setting_user{
    width: 780px;
}

.project_claimer_bott_bb{
    display: grid;
    grid-template-columns: 62px 1fr;
}

.project_claimer_bott_bb .project_claimer_bott_info{
    flex-direction: column;
}

.cviz_btn .next button:hover{
    filter: brightness(0.6);
    transition: 0.5s;
}

.enter_address_item{
    display: flex;
    flex-direction: column;
    color: rgb(255 255 255 / 40%);
    font-size: 12px;
    line-height: 12px;
}

.enter_address_item input{
    font-size: 16px;
    line-height: 32px;
    background: none;
    border: 0;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    color: #fff;
    margin-bottom: 8px;
}

.table_token_found{
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
}

.table_token_found div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding: 0 24px;
}

.table_token_found div p{
    font-size: 16px;
    line-height: 122%;
    color: #fff;
    opacity: 0.4;
}

.table_token_found div span{
    font-size: 16px;
    line-height: 122%;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 12px;
}

.table_token_found div:nth-last-child(2n){
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
}

.tabs_block_container .mt60{
    margin-top: 60px;
}

.drop_file_zone {
    background: none;
    width: 100%;
    border: 1px dashed rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px 0;
}

.drag_upload_file {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    display: grid;
}

.drag_upload_file p {
    font-size: 16px;
    line-height: 122%;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.4;
    margin: 24px 0;
}

.drag_upload_file .selectfile {
    display: none;
}

.drag_upload_file button{
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    background: none;
    height: 48px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 0 95px;
    display: flex;
    align-items: center;
    gap: 16px;
    transition: 0.5s;
}

.drag_upload_file img{
    margin: 0 auto;
}

.drag_upload_file button:hover {
    background: #10141b;
    transition: 0.5s;
}

.token_for{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}

.token_for_name input{
    font-size: 16px;
    line-height: 32px;
    background: none;
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    padding-bottom: 6px;
    color: #fff;
}

.token_for_icon_img{
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.token_for_icon_img img{
    max-width: 48px;
    max-height: 48px;
}

.token_for_icon{
    display: grid;
    grid-template-columns: 48px 1fr;
    gap: 4px;
}

.token_for_icon button{
    width: 100%;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    background: none;
    height: 48px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    transition: 0.5s;
    justify-content: center;
}

.token_for_bottom{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin-top: 40px;
}

.token_for_icon_xls{
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    line-height: 32px;
    color: #fff;
    text-decoration-line: underline;
    width: 100%;
    height: 48px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    justify-content: center;
    position: relative;
}

.token_for_icon_xls_del{
    position: absolute;
    right: 20px;
    top: 12px;
    cursor: pointer;
}

.token_for_icon button:hover{
    background: #10141b;
    transition: 0.5s;
}

.wallets_xls_file{
    height: 104px;
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    padding: 0 24px;
    align-items: center;
    display: flex;
    gap: 12px;
    position: relative;
    font-size: 16px;
    line-height: 122%;
    color: #fff;
}

.wallets_xls_file_del{
    position: absolute;
    right: 24px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;
}

.wallets_xls_file_del:hover{
    transition: 0.5s;
    filter: brightness(0.8);
}

.new_wallet input{
    font-size: 16px;
    line-height: 32px;
    background: none;
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    padding-bottom: 6px;
    color: #fff;
}

.new_wallet{
    //display: grid;
    display: inline-flex;
    grid-template-columns: 1fr 137px;
    gap: 83px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.new_wallet button{
    font-size: 16px;
    line-height: 32px;
    background: no-repeat;
    border: 0;
    color: #fff;
    height: 56px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    transition: 0.5s;
}

.new_wallet button img{
    transform: rotate(45deg);
    opacity: 0.4;
    width: 24px;
    height: 24px;
}

.new_wallet button:hover{
    background: #10141b;
    transition: 0.5s;
}

h5{
    font-size: 16px;
    line-height: 122%;
    color: #fff;
    font-weight: normal;
    margin-bottom: 40px;
    margin-top: 40px;
}

.search_wallet{
    background: rgba(255, 255, 255, 0.04);
    border-radius: 8px;
    padding: 24px;
}

.search_wallet_top{
    position: relative;
    overflow: hidden;
}

.search_wallet_top input{
    width: 100%;
    font-size: 16px;
    line-height: 32px;
    color: #fff;
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    background: none;
    padding-left: 40px;
}

.search_wallet_top img{
    position: absolute;
    top: 6px;
}

.search_wallet_nav{
    display: flex;
    justify-content: space-between;
    margin-top: 38px;
    margin-bottom: 38px;
}

.search_wallet_info{
    font-size: 16px;
    line-height: 122%;
    color: #fff;
}

.search_wallet_btn button{
    font-size: 16px;
    line-height: 32px;
    background: none;
    border: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    color: #fff;
    cursor: pointer;
}

.search_wallet_btn button:hover img{
    transition: 0.5s;
    opacity: 1;
}

.search_wallet_btn button img{
    opacity: 0.4;
    transition: 0.5s;
}

.search_wallet_btn{
    display: flex;
    gap: 20px;
}

.search_wallet_b_item input{
    margin-left: 24px;
    box-shadow: none;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.search_wallet_buttom label::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid #fff;
    opacity: 0.4;
    border-radius: 2px;
    position: absolute;
    left: 24px;
    top: 32px;
}

.search_wallet_buttom label.selected::before {
    background-image: url(/assets/images/CheckBox.png);
    display: inline-block;
    width: 26px;
    height: 26px;
    border: none !important;
    opacity: 1;
    border-radius: 0;
    position: absolute;
    left: 21px;
    top: 29px;
}


.search_wallet_buttom label .actick_ceck {
    background-image: url(/assets/images/CheckBox.png);
    background-size: 18px;
    border: 0;
    width: 29px;
    height: 29px;
    opacity: 1;
    position: absolute;
    top: 29px;
    left: 21px;
    background-size: cover;
}

.search_wallet_buttom label:hover::before{
    pointer-events: none;
    border: 2px solid #fff;
    opacity: 0.8;
}

.search_wallet_buttom label:hover p{
    transition: 0.5s;
    color: #fff;
    opacity: 1;
}

.search_wallet_b_item{
    display: grid;
    width: 100%;
    align-items: center;
    grid-template-columns: 1fr 56px;
}


.daily_vesting_item input{
    margin-left: 24px;
    box-shadow: none;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.daily_vesting_item input+label::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    opacity: 0.4;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
}


.daily_vesting_item input:checked+label::before {
    background-image: url(/assets/images/checked.png);
    background-size: 18px;
    border: 0;
    width: 18px;
    height: 18px;
    opacity: 1;
}

.daily_vesting_input{
    overflow: hidden;
    display: grid;
    align-items: center;
    height: 100%;
}

.daily_vesting_item{
    position: relative;
    padding-left: 36px;
}

.daily_vesting_block{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}

.daily_vesting_input .t{
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    margin: 0;
}

.daily_vesting_item_on .daily_vesting_input input{
    opacity: 1 !important;
    margin: 0 !important;
    width: 100%;
    position: initial;
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    background: none;
    color: #fff;
    height: 32px;
    box-sizing: border-box;
    margin-top: 1px;
    z-index: 2;
}

.disabled .table_unl_input_date::after{
    opacity: 0.4;
}

.disabled .timepicker-ui-input{
    opacity: 0.4;
}

.disabled .detal_saerch_btn_on img{
    opacity: 0.4;
}

.daily_vesting{
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    padding: 24px;
    margin-top: 40px;
}

.daily_vesting_item label{
    display: block;
    height: 100%;
}

.daily_vesting_item_on .t{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: 0.4;
    margin: 0;
}

.search_wallet_b_item label{
    margin: 0;
    cursor: pointer;
    position: relative;
    height: 100%;
}

.search_wallet_b_item:nth-last-child(2n) {
    background: rgba(255, 255, 255, 0.04);
}

.search_wallet_b_item:nth-last-child(2n-1) {
    background: rgba(255, 255, 255, 0.00);
}

.search_wallet_buttom{
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.search_wallet_b_content p{
    color: #fff;
    font-size: 16px;
    line-height: 122%;
    opacity: 0.4;
    margin: 0;
}

.search_wallet_b_content span{
    font-weight: normal;
    font-size: 16px;
    line-height: 122%;
    color: #6FCFB2;
}

.search_wallet_b_content{
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 60px;
}

.detal_saerch .detal_saerch_btn_on{
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 9px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.detal_saerch_btn{
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    z-index: 5;
    display: none;
}



.detal_saerch_btn button{
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 122%;
    background: #36393E;
    color: rgb(255, 255, 255 , 0.4);
    border: 0;
    width: 180px;
    height: 48px;
    gap: 12px;
    padding: 12px;
    cursor: pointer;
}

.detal_saerch_btn button:nth-last-child(2n-1) {
    background: #2F3238;
}

.detal_saerch_btn button:nth-last-child(2n-1) img {
    opacity: 0.4;
}

.detal_saerch_btn button img{
    width: 24px;
    object-fit: none;
}

.detal_saerch_btn button:hover{
    color:#fff;
}

.detal_saerch{
    margin-right: 24px;
    position: relative;
}

.detal_saerch:hover .detal_saerch_btn{
    display: block;
}

.search_wallet_buttom .search_wallet_b_item:last-of-type .detal_saerch_btn{
    bottom: 0;
}

.search_wallet_pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}

.search_wallet_pagination button{
    width: 32px;
    height: 32px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 9px;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 8px;
}

.search_wallet_pagination_next img{
    transform: rotate(180deg);
}

.search_wallet_pagination a{
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.56;
    text-decoration: none;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.search_wallet_pagination button:hover{
    background: #ffffff52;
    transition: 0.5s;
}

.search_wallet_pagination a:hover{
    opacity: 1;
    transition: 0.5s;
}

.search_wallet_pagination span{
    color: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table_token_found_filter{
    background: rgba(255, 255, 255, 0.04);
    margin-bottom: 40px;
}

.popap{
    display:none !important;
}

.connect_wallet_is-open .popap,.change_popap_is-open .popap,.remove_popap_is-open .popap{
    display: block !important;
}

.connect_wallet_is-open .connect_wallet_popap{
    display: flex !important;
}

.change_popap_is-open .change_popap{
    display: flex !important;
}

.remove_popap_is-open .remove_popap{
    display: flex !important;
}

.table_token_found_filter div:nth-last-child(2n){
    background: none;
    backdrop-filter: none;
}

.table_token_found_filter button{
    width: 100%;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    background: none;
    height: 48px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    transition: 0.5s;
    justify-content: center;
    padding: 0 20px;
}

.table_token_found_filter button:hover{
    background: #10141b;
    transition: 0.5s;
}

.icon_token{
    width: 32px;
    height: 32px;
}

header a.connect_wallet{
    background: linear-gradient(90deg, #53F6ED 0%, #53A8F6 100%);
    border-radius: 8px;
    color: #000;
    border: 0;
    gap: 15px;
}

.project_info{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 80px;
}

.project_info_item{
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    display: flex;
    padding: 22px 24px;
    gap: 24px;
}

.project_info_content p{
    margin: 0;
    margin-top: 4px;
    font-size: 16px;
    line-height: 122%;
    color: #fff;
    opacity: 0.4;
}

.project_info_item .t{
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #fff;
}

.project-top{
    display: grid;
    grid-template-columns: 1fr 380px;
    gap: 20px;
}

.project-top-left{
    position: relative;
    overflow: hidden;
    display: flex;
}

.project-top-left input{
    font-size: 16px;
    line-height: 32px;
    background: none;
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    padding-bottom: 6px;
    color: #fff;
    padding-left: 40px;
}

.project-top-left img{
    position: absolute;
    top: 6px;
}

.project-top-right a{
    font-size: 16px;
    line-height: 32px;
    background: none;
    border: 0;
    color: #fff;
    opacity: 0.4;
    height: 56px;
    cursor: pointer;
    transition: 0.5s;
    text-decoration: none;
}

.project-top-right{
    display: flex;
    gap: 40px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.project-top-right a:hover{
    opacity: 1;
    transition: 0.5s;
}

.project-top-right a.act{
    border-bottom: 1px solid #6FCFB2;
    opacity: 1;
    margin-bottom: -1px;
}

.project-bottom{
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    margin-top: 32px;
    backdrop-filter: blur(40px);
}

.project-bottom-top{
    display: grid;
    padding: 0 24px;
    grid-template-columns: 250px 190px 190px 190px 150px 175px auto;
    font-size: 16px;
    line-height: 122%;
    color: #fff;
    opacity: 0.4;
    height: 80px;
    align-items: center;
    background: rgba(255, 255, 255, 0.04);
    gap: 8px;
}

.all_clime {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  margin-top: 32px;
  backdrop-filter: blur(40px);
}

.project-bottom-b{
    display: grid;
    padding: 15px 24px;
    height: auto;
    grid-template-columns: 250px 190px 190px 190px 150px 175px auto;
    font-size: 16px;
    line-height: 122%;
    color: rgb(255 255 255 / 64%);
    align-items: center;
    gap: 8px;
}

.all_clime_item{
    display: grid;
    grid-template-columns: 1fr 1fr 175px;
    gap: 25px;
    padding: 0 25px;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 162%;
    color: #FFFFFF;
    min-height: 88px;
}

.all_clime_item a {
    box-sizing: border-box;
    height: 56px;
    width: 175px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
    background: linear-gradient(90deg, #53F6ED 0%, #53A8F6 100%);
    opacity: 0.4;
    border-radius: 8px;
    color: #000 !important;
    border: 0;
}

.all_clime_item_activ a{
    opacity: 1;
}

.all_clime_item_end a{
    background: rgba(111, 207, 178, 0.4);
    opacity: 1;
    color: #6FCFB2;
}

.all_clime_item:nth-last-child(2n) {
    background: rgba(255, 255, 255, 0.04);
}

#platforms .dd-select{
    width: 360px !important;
    background: none !important;
    border: 0 !important;
}

#vesting_select .dd-select{
    width: 360px !important;
    background: none !important;
    border: 0 !important;
}

#platforms .dd-selected{
    padding: 0 !important;
    display: flex;
    align-items: center;
    margin: 8px 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    padding-bottom: 5px !important;
}

#vesting_select .dd-selected-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #fff;
}

#vesting_select .dd-selected{
    padding: 0 !important;
    display: flex;
    align-items: center;
    margin: 8px 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    padding-bottom: 5px !important;
}

.dd-pointer-down {
    border: solid 5px transparent;
    border-top: solid 5px #fff !important;
}

.platforms_l{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: 0.4;
    margin-top: 40px;
    display: block;
}

.dd-options{
    width: 360px !important;
    backdrop-filter: blur(40px);
}

.dd-options{
    box-shadow: none !important;
}

#platforms .dd-pointer-up{
    border-bottom: solid 5px rgb(255, 255, 255) !important;
}


.dd-option-image, .dd-selected-image {
    vertical-align: middle;
    float: left;
    margin-right: 10px !important;
    max-width: 20px !important;
}

#platforms .dd-selected-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #fff;
}

.all_clime_item_end a::before{
    content:'';
    background: url(/assets/images/end.png) no-repeat;
    width: 18px;
    height: 14px;
    margin-right: 15px;
}

.all_clime_item .t{
    opacity: 0.64;
}

.all_clime_item p{
    margin: 0;
}

.project-bottom-b div{
    display: flex;
    align-items: center;
    gap: 15px;
}

.blockchain_filter{
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 32px;
}

.blockchain_filter .blockchain_item input:checked+label {
    background: rgba(255, 255, 255, 0.04);
}

.project-bottom-b div p{
    margin: 0;
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 12px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 8px;
    width: auto;
}

.h2_p{
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #FFFFFF;
    opacity: 0.8;
    margin-top: -80px;
    margin-bottom: 80px;
}

.project-bottom-b div a{
    box-sizing: border-box;
    height: 56px;
    width: 175px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
    background: linear-gradient(90deg, #53F6ED 0%, #53A8F6 100%);
    opacity: 1;
    border-radius: 8px;
    color: #000 !important;
    border: 0;
}

.project_activ div a{
    opacity: 1;
}

.project_activ div:nth-last-child(2){
    color: #fff;
}

.project-bottom-b:nth-last-child(2n) {
    background: rgba(255, 255, 255, 0.04);
}

.project_claimer{
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    display: flex;
    padding: 22px 24px;
    gap: 45px;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 100px;
}

.nw_lock img{
    margin-left: 12px;
}

.project_claimer_top{
    display: flex;
    gap: 24px;
    align-items: center;
    position: relative;
}

.project_claimer_img{
    display: flex;
}

.project_claimer_img img{
    width: 48px;
    height: 48px;
}

.project_claimer_info p{
    margin: 0;
    font-size: 16px;
    line-height: 122%;
    color: rgb(255 255 255 / 40%);
    display: flex;
    align-items: center;
    gap: 10px;
}

.project_claimer_info .t{
    font-size: 32px;
    line-height: 125%;
    color: #fff;
    word-break: break-word;
}

.footer-right{
    .social{
        display: flex;
        justify-content: end;
    }
}

.project_claimer_token_wrapper {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-left: auto;
}

.project_claimer_token{
    border-radius: 8px;
    color: rgb(255 255 255 / 64%);
    font-size: 16px;
    line-height: 122%;
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 8px 12px;
}

.project_claimer_info_cant_claim {
    border-radius: 8px;
    padding: 8px 12px;
    color: rgb(255 255 255 / 64%);
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.project_claimer_token_item{
    display: flex;
    align-items: center;
    gap: 12px;
}

.project_claimer_token img{
    width: 24px;
    height: 24px;
}

.project_claimer_bott{
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 0;
}

.project_claimer_bott_info{
    display: flex;
    justify-content: space-between;
}

.project_claimer_bott_img{
    margin: 0 auto;
}

.project_claimer_bott_info p{
    font-size: 14px;
    line-height: 122%;
    color: #fff;
    opacity: 0.4;
    margin: 0;
}

.project_claimer_bott_info .t{
    font-size: 16px;
    line-height: 122%;
    color: #fff;
}

.project_claimer_bott_progress progress{
    width: 100%;
    height: 2px;
    border-radius: 8px;
    background-color: #324D45;
}

.project_claimer_bott_progress progress::-webkit-progress-value{
    background-color: #6fcfb2;
    border-radius: 8px;
}

.project_claimer_bott_progress progress::-webkit-progress-bar {
    background-color: #324D45;
    border-radius: 8px;
}

.project-top_1fr{
    grid-template-columns: 1fr;
}

.setting_user_item{
    width: 732px;
    margin: 0 auto;
    margin-top: 38px;
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    padding: 24px;
    padding-top: 40px;
    height: fit-content;
}

.setting_user .t {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.setting_user_item .table_token_found_filter:last-of-type{
    margin-bottom: 0;
}

.vesting .t{
    font-size: 16px;
    line-height: 122%;
    color: #FFFFFF;
    opacity: 0.64;
    margin-bottom: 4px;
}

.vesting_item{
    display: grid;
    grid-template-columns: 165px 165px 185px 170px 1fr;
    align-items: center;
    height: 88px;
    padding: 0 24px;
}

.vesting p{
    font-size: 16px;
    line-height: 122%;
    margin: 0;
    color: #fff;
}

.vesting .p{
    font-size: 16px;
    line-height: 122%;
    margin: 0;
    color: #fff;
    text-overflow: ellipsis;
    width: 95px;
    overflow: hidden;
    opacity: 0.64;
}

.pr_top{
    display: flex;
    justify-content: space-between;
}

.a_locked{
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    width: 170px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: blink;
    position: relative;
}

.a_locked::before{
    content: '';
    width: 16px;
    height: 21px;
    background: url(/assets/images/Locked.png);
    margin-right: 20px;
}

.a_unlocked{
    background: rgba(111, 207, 178, 0.2);
    border-radius: 8px;
    width: 170px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6FCFB2;
    text-decoration: blink;
    position: relative;
}

.a_unlocked::before{
    content: '';
    width: 24px;
    height: 14px;
    background: url(/assets/images/Unlocked.png);
    margin-right: 16px;
}

.a_claim{
    background: #6FCFB2;
    border-radius: 8px;
    width: 170px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    text-decoration: blink;
    position: relative;
}

.a_claim::before{
    content: '';
    width: 22px;
    height: 16px;
    background: url(/assets/images/clime.png);
    margin-right: 16px;
}

.vesting{
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    backdrop-filter: blur(40px);
    margin-top: 20px;
}

.pr_top,.pr_bott{
    margin-left: 20px;
}

.vesting_item:nth-last-child(2n) {
    background: rgba(255, 255, 255, 0.04);
}

.vesting_item a:hover{
    transition: 0.5s;
    filter: brightness(0.5);
}

.pr_bott progress{
    width: 100%;
    height: 4px;
    border-radius: 8px;
    background-color: #324D45;
}

.pr_bott progress::-webkit-progress-value{
    background-color: #6fcfb2;
    border-radius: 8px;
}

.pr_bott progress::-webkit-progress-bar {
    background-color: #324D45;
    border-radius: 8px;
}

.popap{
    position: fixed;
    width: 100%;
    height: 100%;
    background: #0000009c;
    top: 0;
    z-index: 10;
}

.connect_wallet_popap{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background: #0D0E0F;
    backdrop-filter: blur(40px);
    padding: 32px;
    border-radius: 8px;
    color: #fff;
}

.connect_wallet_popap .t{
    font-size: 16px;
    line-height: 32px;
}

.connect_wallet_popap .t span{
    opacity: 0.4;
    margin-left: 10px;
}

.connect_wallet_popap p{
    line-height: 32px;
}

.connect_wallet_popap a{
    font-size: 16px;
    line-height: 32px;
    background: no-repeat;
    border: 0;
    color: #fff;
    height: 56px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
    text-decoration: none;
}

.connect_wallet_popap_btn{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

body .a_color{
    background: linear-gradient(90deg, #53F6ED 0%, #53A8F6 100%);
    border: 0;
    color: #000;
}

.close_wallet_popap{
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
}

.close_wallet_popap:hover{
    transition: 0.5s;
    filter: brightness(0.5);
}

.change_popap{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background: #0D0E0F;
    backdrop-filter: blur(40px);
    padding: 32px;
    border-radius: 8px;
    color: #fff;
}

.change_popap_close{
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
}

.change_popap_close:hover{
    transition: 0.5s;
    filter: brightness(0.5);
}

.change_popap .t{
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 24px;
}

.change_popap .t span{
    opacity: 0.4;
    margin-left: 10px;
}

.change_popap .p div{
    font-size: 16px;
    line-height: 32px;
    opacity: 0.4;
}

.change_popap .to{
    font-size: 16px;
    line-height: 32px;
    opacity: 0.4;
}

.change_popap .p span{
    line-height: 32px;
}

.change_popap .p{
    margin-bottom: 24px;
}

.change_popap input{
    font-size: 16px;
    line-height: 32px;
    background: none;
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    margin-bottom: 24px;
}

.change_popap_btn{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.change_popap_btn button{
    font-size: 16px;
    line-height: 32px;
    background: no-repeat;
    border: 0;
    color: #fff;
    height: 56px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
    text-decoration: none;
}

.change_popap_btn button img{
    margin-right: 15px;
    opacity: 0.4;
}

.change_popap_btn button:hover{
    background: #10141b;
    transition: 0.5s;
}

.remove_popap{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background: #0D0E0F;
    backdrop-filter: blur(40px);
    padding: 32px;
    border-radius: 8px;
    color: #fff;
}

.request_popap_is-open .request_popap{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background: #0D0E0F;
    backdrop-filter: blur(40px);
    padding: 32px;
    border-radius: 8px;
    color: #fff;
    width: 500px;
}

.request_popap_is-open .popap{
    display: block !important;
}

.wait_animation{
    animation: wait_animation 1s infinite ;
    margin-top: 40px;
}

.wait_info{
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
}

.change_popap2{
    display: none;
}

@keyframes wait_animation {
  from {
    transform: rotate(0deg);}
  to {
    transform: rotate(360deg); }
}

.span_popap{
    font-weight: 400;
    font-size: 16px;
    line-height: 162%;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 60px;
}

.change_popap2 {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background: #0D0E0F;
    backdrop-filter: blur(40px);
    padding: 32px;
    border-radius: 8px;
    color: #fff;
}

.icons_popap{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.t_popap{
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 24px;
}

.p_popap{
    max-width: 515px;
    font-weight: 400;
    font-size: 16px;
    line-height: 162%;
    color: #FFFFFF;
    opacity: 0.8;
}

.popap_input_wallet{
    margin-top: 60px;
    margin-bottom: 15px;
}

.change_popap_btn_done{
    grid-template-columns:1fr;
}

.change_popap2_is-open .popap{
    display: block !important;
}

.change_popap2_close {
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
}

.change_popap2_is-open .change_popap2{
    display: flex;
}

.popap_input_wallet_t{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: 0.4;
}

.popap_input_wallet input{
    font-size: 16px;
    line-height: 32px;
    background: none;
    border: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    padding-bottom: 6px;
    color: #fff;
}

.remove_popap_close{
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
}

.remove_popap_close:hover{
    transition: 0.5s;
    filter: brightness(0.5);
}

.remove_popap .t{
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 24px;
}

.remove_popap .t span{
    opacity: 0.4;
    margin-left: 10px;
}

.remove_popap_info{
    background: rgba(255, 255, 255, 0.04);
    border-radius: 8px;
    padding: 12px;
    margin: 24px 0;
}

.remove_popap_info p{
    line-height: 32px;
    margin: 0;
}

.remove_popap_info span{
    font-size: 16px;
    line-height: 122%;
    color: #6FCFB2;
}

.remove_popap_info a{
    display: flex;
    justify-content: end;
    font-size: 16px;
    line-height: 32px;
    text-decoration-line: underline;
    color: #fff;
}

@media (max-width:1500px) {
    .bg_1::before{
        left: -150px;
    }
}

@media (max-width: 1600px) {
  .project-bottom-top{
    grid-template-columns: 140px 140px 140px 140px 150px 175px auto auto;
  }

  .project-bottom-b{
    grid-template-columns: 140px 140px 140px 140px 150px 175px auto auto;
  }
}

@media (max-width:1180px) {
    .container{
        width: 780px;
    }
    .project_claimer_bott{
        grid-template-columns:1fr ;
    }
    .tabs_s {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .vesting_item {
        display: grid;
        grid-template-columns: 1fr;
        gap: 7px;
        text-align: center;
        align-items: center;
        height: auto;
        padding: 7px 24px;
    }
    .a_locked{
        margin: 0 auto;
    }
    .a_unlocked{
        margin: 0 auto;
    }
    .a_claim{
        margin: 0 auto;
    }
    h2 {
        font-size: 54px;
        line-height: 100%;
    }
    .project-bottom-top{
        grid-template-columns: 120px 120px 140px 140px 140px 190px;
    }
    .project-bottom-b{
        grid-template-columns: 120px 120px 140px 140px 140px 70px;
    }
    body .project_claimer_bott_ver2 {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 380px;
        gap: 24px;
    }
    .summary_set {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .project-bottom_ver2 .project-bottom-b{
        grid-template-columns: 120px 120px 140px 140px 140px 190px;
    }
}

@media (max-width:1100px) {
    .container{
        width: 90%;
    }
    #vesting_select .dd-select {
        width: 290px !important;
    }
    .dd-options {
        width: 290px !important;
    }
    .dd-options {
        width: 290px !important;
    }
    .project_claimer_bott_info {
        display: grid;
        justify-content: center;
        text-align: center;
        width: 100%;
        gap: 15px;
    }
    .table_unl_item {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
    }

    .times_block{
        grid-template-columns: 1fr;
    }

    .cviz_btn2{
        grid-template-columns: 1fr;
    }

    .tabs_select_result div {
        display: grid;
        justify-content: center;
        text-align: center;
    }

    .block_balance button{
        height: 56px;
    }

    .daily_vesting_block{
        grid-template-columns: 1fr;
    }

    .tabs_select_result div {
        display: grid;
        justify-content: center;
    }

    .block_balance{
        grid-template-columns: 1fr;
    }

    .table_unl_n{
        display: flex;
        justify-content: center;
    }

    .vesting_set {
        display: grid;
        grid-template-columns: 1fr;
    }
    .cliff_block{
        grid-template-columns: 1fr;
    }
    .all_clime_item {
        display: grid;
        grid-template-columns: 1fr;
        padding: 15px !important;
        text-align: center;
    }
    .all_clime_item a{
        margin: 0 auto;
    }
    .project-bottom-b div p{
        justify-content: center;
    }
    .project_claimer_top {
        display: grid;
        gap: 5px;
    }
    body .project_claimer_bott_ver2 {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr;
    }
    .summary_set .setting_user {
        width: auto;
    }
    .project_claimer_info p{
        overflow: hidden;
        text-overflow: ellipsis;
        width: 310px;
    }
    .project_claimer_bott{
        display: flex;
        flex-wrap: wrap;
    }
    .project_claimer_bott_progress{
        width: 100%;
    }
    .project_info {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        margin-bottom: 40px;
    }
    .project-top {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .setting_user_item {
        width: 340px;
        padding: 10px;
    }
    .table_token_found_filter button{
        line-height: 15px;
        padding: 0 10px;
    }
    .table_token_found div span{
        overflow-wrap: anywhere;
        margin-left: 20px;
        text-align: right;
    }
    .table_token_found div{
        padding: 0 10px;
    }
    .new_wallet{
        grid-template-columns: 1fr;
    }
    .search_wallet_btn {
        display: grid;
        gap: 10px;
    }
    .search_wallet_nav {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .search_wallet_b_item{
        grid-template-columns: 1fr;
    }
    .search_wallet_b_content p{
        max-width: 200px;
        overflow-wrap: break-word;
    }
    .search_wallet_b_content{
        text-align: right;
    }
    .search_wallet_b_content span{
        margin-right: 24px;
    }
    .detal_saerch {
        margin-right: 0;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: -10px;
        margin-bottom: 15px;
    }
    footer .container{
        justify-content: center;
        width: 100%;
    }
    .footer-right{
        margin-top: 15px;
        text-align: center;
    }
    footer .policy {
        flex-direction: column;
        opacity: 0.8;
        gap: 10px;
    }
    footer .logo{
        text-align: left;
    }
    .footer-right .social {
        display: flex;
        gap:20px;
        justify-content: flex-start !important;
    }
    .menu_btn_mobil {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .menu_btn_mobil span {
        width: 25px;
        height: 2px;
        background: #fff;
        transition: 0.5s;
    }
    .heads {
        position: absolute;
        width: -webkit-fill-available;
        height: 100%;
        top: 0;
        padding: 20px;
        background: rgb(12 16 25);
        backdrop-filter: blur(48px);
        display: none;
        height: auto;
        z-index: 5;
    }
    .menu_openid .menu_btn_mobil span:nth-last-child(2) {
        transform: rotate(-45deg);
        transition: 0.5s;
    }
    .menu_openid .menu_btn_mobil span:nth-last-child(1) {
        transform: rotate(45deg);
        position: absolute;
        transition: 0.5s;
    }
    .menu_openid .menu_btn_mobil span:nth-last-child(3) {
        display: none;
    }
    .head-right{
        display: grid;
    }
    .heads_off{
        text-align: right;
        border-bottom: 1px solid #ffffff1f;
        margin-bottom: 25px;
        padding-bottom: 2px;
    }
    .menu_openid .heads{
        display: block;
    }
    .blockchain {
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
    }
    .token_for{
        grid-template-columns: 1fr;
    }
    #drag_upload_file button{
        padding: 0 15px;
    }
    .token_for_bottom{
        grid-template-columns: 1fr;
    }
    .project-bottom-top{
        display: flex;
        flex-wrap: wrap;
    }
    .project-bottom-b{
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 15px;
        gap: 10px;
    }
    .project-bottom-b div a{
        height: 48px;
        width: 100%;
    }
    .project-bottom-top{
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: auto;
        padding: 15px 15px;
    }
    .bg_1::after{
        right: -250px;

    }
    .bg_2::after{
        right: 0px;
    }
    .bg_1, .bg_2, .bg_3{
        zoom: 0.5;
    }
    .connect_wallet_popap{
        width: -webkit-fill-available;
        margin: 0 15px;
    }
    .connect_wallet_popap_btn{
        grid-template-columns:1fr;
    }
    .connect_wallet_popap p {
        line-height: 32px;
        overflow-wrap: anywhere;
    }
    .change_popap{
        width: -webkit-fill-available;
    }
    .change_popap_btn{
        grid-template-columns: 1fr;
    }
    .change_popap .p span{
        overflow-wrap: break-word;
    }
    .remove_popap{
        width: -webkit-fill-available;
    }
    .remove_popap_info p {
        line-height: 32px;
        margin: 0;
        overflow-wrap: anywhere;
    }
    .successful_popap_is-open .successful_popap{
        width: 300px;
    }
    .wait_is-open .wait_popap{
        width: 300px;
    }
    .request_popap_is-open .request_popap{
        width: 300px;
    }
    .change_popap2_is-open .change_popap2{
        width: 300px;
    }
    .remove_popap{
        width: 300px;
    }
    .change_popap_is-open .change_popap{
        width: 300px;
    }
    .connect_wallet_is-open .connect_wallet_popap{
        width: 300px;
        margin: 0;
    }
}

.swal2-content {
  font-size: 13px !important;
}


.swal2-popup{
  background: #0B0F16 !important;
}

.swal2-styled.swal2-confirm {
//   padding: 16px 32px;
//   gap: 16px;

//   width: 515px;
//   height: 64px;

  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 8px;
  color: #FFFFFF;

  background-color: #0B0F16 !important;
}
.swal2-title{
  color:#FFFFFF !important;
}

.swal2-cancel.swal2-styled{
//   padding: 16px 32px;
//   gap: 16px;

//   width: 515px;
//   height: 64px;

  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 8px;
  color: #FFFFFF;

  background-color: #0B0F16 !important;
}

.request-pending {
  border: none !important;
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}


.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);

  &.cdk-overlay-backdrop-showing {
    opacity: 1;
  }
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.dialog-container {
  background: rgba(255, 255, 255, 0.04);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  color: white;
  padding: 32px;
  border-radius: 8px;
  width: 450px;

  @media screen and (max-width: 500px) {
    width: auto;
    min-width: 300px;

    .title > h3 {
      font-size: 17px;
    }
  }

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 125%;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 33px;
  }

  .btn-close {
    display: inline-block;
    position: absolute;
    right: 24px;
    top: 24px;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0;
    margin: 0;
    line-height: 1;
    background: none;
    font-size: 24px;
    opacity: 0.4;
    z-index: 1;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:after {
      color: white;
      content: "\e963";
      font-family: "icomoon";
    }

    &:hover {
      opacity: 1;
    }
  }
}

.mobileTitleTable{
    display: none !important;
}

.navigation_slider{
    display: none;
}

.mat-dialog-container {
  box-shadow: none;
  background: none;
  color: unset;
}

@media (max-width:1350px) {

    .navigation_slider{
        display: block;
    }

    .mat-dialog-container{
        padding: 0 !important;
    }

    .enter_address_example{
        overflow-wrap: anywhere !important;
    }

    .drag_upload_file button{
        padding: 0 15px !important;
    }

    .project-bottom_ver2{
        position: relative;
    }

    .project-bottom-top{
        display: none;
    }

    .navigation_slider .prevSlide, .navigation_slider .nextSlide {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        margin-top: -22px;
        padding: 16px;
        color: white !important;
        font-weight: bold;
        font-size: 18px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
    }

    .navigation_slider .prevSlide{
        left: -10px;
    }

    .navigation_slider .nextSlide {
        right: -10px;
    }

    .project-bottom_ver2 .activeSl{
        display: grid;
    }

    .project-bottom_ver2 .showSl{
        display: none;
    }

    .project-bottom_ver2 .project-bottom-b {
        -webkit-animation-name: fade;
        -webkit-animation-duration: 1.5s;
        animation-name: fade;
        animation-duration: 1.5s;
    }

    @-webkit-keyframes fade {
        from {
            opacity: .4
        }
        to {
            opacity: 1
        }
    }

    @keyframes fade {
        from {
            opacity: .4
        }
        to {
            opacity: 1
        }
    }

    .project-bottom_ver2 .project-bottom-b{
        grid-template-columns: 1fr;
        height: auto;
        padding: 16px 20px;
        gap: 15px;
        align-items: baseline !important;
    }

    .project-bottom-b div {
      width: 100%;
      gap: 15px;
    }

    .project-bottom-b div div{
      width: fit-content;
      cursor: pointer;
      gap: 15px;
      font-size: 12px;
      line-height: 163%;
      color: #FFFFFF;
      opacity: 0.64;
    }

    .project-bottom-b div a{
      height: 48px;
      width: 100%;
    }

    .mobileTitleTableBl{
        display: flex;
        flex-direction: column;
        align-items: baseline !important;
    }

    .mobileTitleTable{
        font-weight: 400;
        font-size: 12px;
        line-height: 163%;
        color: #FFFFFF;
        opacity: 0.4 !important;
        display: block !important;
    }

    body .project-bottom_ver2 .project-bottom-name{
        margin-right: 0 !important;
        width: 100%;
    }

    body .project-bottom_ver2 .project-bottom-name p{
        justify-content: flex-start;
    }

    h2 {
        font-size: 40px;
        line-height: 125%;
        font-weight: 400;
    }

    .h2_p{
        display: none;
    }

    .project-top-right a{
        height: 36px;
    }

    .blockchain .blockchain_item input + label::before{
        margin-top: 20px;
    }

    .blockchain .blockchain_item{
        height: 56px;
    }

    .blockchain .blockchain_item label{
        height: 56px;
    }

    .blockchain .blockchain_item .blockchain_item_c{
        height: 56px;
    }

    .project-top-left input {
        font-size: 14px;
    }

    .request-refund-btn, .edit-claim {
      margin: auto;
    }
}
.fullwidth{
    width: 100%;
}
