.dlg-light {
  background: rgba(255, 255, 255, 0.04);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
}

.btn-close {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 0;
  line-height: 1;
  background: none;
  font-size: 24px;
  color: white;
  opacity: 0.4;
  z-index: 1;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:after {
    content: "\e963";
    font-family: "icomoon";
  }
  &:hover {
    color: #353E51;
  }
}

